import $ from "jquery";

import imagesLoaded from "imagesloaded";
import Isotope from "isotope-layout";

$(document).ready(function() {

    //isotope
    if ($('#grid').length) {
        var iso = new Isotope( '#grid', {
            //layoutMode: 'fitColumns',
            itemSelector: '.grid-item'
        });
    }
    // bind filter button click
    $('.filter').on( 'click', 'span', function() {
        var filterValue = $( this ).attr('data-filter');
        // use filterFn if matches value
        //filterValue = filterFns[ filterValue ] || filterValue;
        iso.arrange({ filter: filterValue });
    });
    // change is-checked class on buttons
    $('.filter').each( function( i, buttonGroup ) {
        var $buttonGroup = $( buttonGroup );
        $buttonGroup.on( 'click', 'span', function() {
            $buttonGroup.find('.is-checked').removeClass('is-checked');
            $( this ).addClass('is-checked');
        });
    });

})
